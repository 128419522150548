$(window).on("resize", function (){
	if ($(this).width() > 1025) {
		if (window.device == 'mobile') {
			location.reload()
		}
		window.device = 'desktop';
	}else{
		if (window.device == 'desktop') {
			location.reload()
		}
		window.device = 'mobile';
	}
}).trigger("resize")

$.fn.ryderCool = function(option) {
	return this.each(function() {
		var $this = $(this);

		var deFault = {
			hook: 0.9,
			repeat: false,
			enter_check: true,
			leave_check: true,
			count: 0,
			enter() {},
			leave() {}
		};

		var setting = $.extend(deFault, option);

		function ryderScrolling() {
			var scrollTop = $(window).scrollTop(),
				elementOffset = $this.offset().top,
				distance = (elementOffset - scrollTop),
				windowHeight = $(window).height(),
				breakPoint = windowHeight * setting.hook,
				leavePoint = $this.height() - windowHeight * (1 - setting.hook);

			if (distance > breakPoint || distance < -leavePoint) {

				if (setting.count >= 1) {
					setting.enter_check = setting.repeat;
				}

				setting.leave_check && setting.leave($this);
				setting.leave_check = false;

			}else if (distance < breakPoint) {

				setting.enter_check && setting.enter($this);
				setting.enter_check && setting.count++;
				setting.enter_check = false;
				setting.leave_check = true;
			}
		}

		$(window).on("scroll", ryderScrolling).trigger("scroll");
	});
};

class RyderMarquee {
	constructor(el, direct = 0) {
		this.hero = $(el).parent().get(0)
		this.wrapper = el
		this.delta = 0
		this.transform = 0
		this.step = (device == 'mobile') ? 0.4 : 0.8
		this.direct = direct % 2
		this.hover = false

		if (this.direct == 1) {
			this.wrapper.style.transform = `translate3d(-${this.wrapper.getBoundingClientRect().width / 2}px, 0, 0)`;
			this.transform = -this.wrapper.getBoundingClientRect().width / 2
		}

		// if (device != 'mobile') {
		// 	$(el).hover(() => {
		// 		this.hover = true
		// 	}, () => {
		// 		this.hover = false
		// 	})
		// }
	}

	animate() {
		if (!this.hover) {
			this.transform += this.step
		}

		if (this.direct == 1) {
			if (this.transform > 0) {
				this.transform = -this.wrapper.getBoundingClientRect().width / 2;
			}
			this.wrapper.style.transform = `translate3d(${this.transform}px, 0, 0)`;
		} else {
			if (this.transform > this.wrapper.getBoundingClientRect().width / 2) {
				this.transform = 0;
			}
			this.wrapper.style.transform = `translate3d(-${this.transform}px, 0, 0)`;
		}
	}

	render() {
		this.scrollY = $(window).scrollTop()

		const bounding = this.hero.getBoundingClientRect();
		const distance = (window.innerHeight + this.scrollY) - (bounding.top + this.scrollY);
		const percentage = distance / ((window.innerHeight + bounding.height) / 100);

		this.animate();
	}

	create() {
		gsap.ticker.add(this.render.bind(this));
	}
}

$(".marquee").each(function (i, el) {
	var $copy = $(el).contents().clone()
	$(el).append($copy)

	var ryderMarquee = new RyderMarquee(el, i).create()
})

$(".menuOpen").on("click", function(){
	$(".menuWrap").removeClass("opacity-0 pointer-events-none");
})

$(".menuWrap .close").on("click", function(){
	$(".menuWrap").addClass("opacity-0 pointer-events-none");
})

var _scrollLast = 0
$(window).on("scroll", () => {
	var _scrollTop = $(window).scrollTop()
	var _allBottom = $("body").height() - $(window).height() - 100

	if (_scrollTop < 0 || _scrollTop > _allBottom) {
		return false;
	}

	if (_scrollTop > _scrollLast) {
		$("#topmenuWrap").addClass("is-hide")
	} else {
		$("#topmenuWrap").removeClass("is-hide")
	}

	_scrollLast = _scrollTop
})

// var pjax = new Pjax({
// 	// 在页面进行 PJAX 时需要被替换的元素或容器，一条一个 CSS 选择器，数组形式
// 	selectors: [
// 		"title",
// 		"meta[name=description]", // 如果是全部 meta 替换的话，只需要写 meta
// 		".js-Pjax"
// 	],
// 	cacheBust: false
// })
// const pjax = new Pjax({
//     selectors: ["title", ".js-Pjax"],
//     switches: {
//         ".js-Pjax": Pjax.switches.sideBySide
//     },
//     switchesOptions: {
//         ".js-Pjax": {
//             classNames: {
//                 // 添加到要替换的旧元素中的class, 示例（一个淡出动画）
//                 remove: "Animated Animated--reverse Animate--fast Animate--noDelay",
//                 // 添加到要替代旧元素的新元素的class, 示例（一个淡入动画）
//                 add: "Animated",
//                 // 在返回时添加到元素上的class
//                 backward: "Animate--slideInRight",
//                 // 在前进时添加到元素上的class (也应用于新页面)
//                 forward: "Animate--slideInLeft"
//             },
//             callbacks: {
//                 // 为了在两页的同时完成一个很好的过渡
//                 // 我们正在对要移除的元素进行绝对定位
//                 // & 我们需要实时参数去创造一些很棒的内容
//                 // 查看下面的相关CSS
//                 removeElement: function (el) {
//                     el.style.marginLeft = "-" + (el.getBoundingClientRect().width / 2) + "px"
//                 }
//             }
//         }
//     }
// })